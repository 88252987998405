import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  logo_width: '150px',
  withoutLogin: true,
  map_hide: true,
  community: true,
};

export default config;
